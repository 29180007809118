import {
  Form,
  Button,
  Typography,
  Modal,
  Row,
  Col,
  Divider,
  message,
  Select,
  Spin,
  Checkbox
} from "antd";
import { SaveOutlined } from "@ant-design/icons";
import { useMutation } from "react-query";
import { queryClient } from "../../queryClient";
import { editMealSuggestions, getInternalProduct } from "../../api";
import { useMealSuggestions } from "./store";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

const { Title } = Typography;
const { Option } = Select;

export const PureInternalModal = () => {
  const {
    isInternalModal,
    filterData,
    setIsVendorModal,
    setIsInternalModal,
    setIsCreateModal,
    setFilterData,
  } = useMealSuggestions();
  const [form] = Form.useForm();
  const [allProductCategory, setAllProductCategory] = useState(false);

  const resetState = () => {
    setFilterData(null)
    setIsVendorModal(false)
    setIsInternalModal(false)
    setIsCreateModal(false)
    setAllProductCategory(false)
    form.resetFields()

  };

  const { mutate: editMutate, isLoading: editLoading } = useMutation(
    editMealSuggestions,
    {
      onSuccess: (data) => {
        if (data.success) {
          message.success(data.msg);
          queryClient.invalidateQueries("meal_suggestions");
          resetState();
          setIsCreateModal(false);
        } else {
          message.error(data.msg);
        }
      },
      onError: (e) => {
        message.error(e.message);
      },
    }
  );



  const { isLoading: productCategoryLoading, data: productCategory } = useQuery(
    ["InternalProduct", 1, 1000],
    getInternalProduct,
    {
      refetchOnWindowFocus: false,
      retry: 2,
      enabled: !!isInternalModal,
    }
  );

  useEffect(() => {
    if (filterData && isInternalModal) {
      if (JSON.stringify(filterData.internal_product_groups) === JSON.stringify([-1])) {
        setAllProductCategory(true)
        form.resetFields()
      } else {
        setAllProductCategory(false)
        form.setFieldsValue({
          internal_product_groups: filterData.internal_product_groups,
        });
      }
    }
  }, [isInternalModal,]);

  const onFinish = (values) => {
    const { id, name, limit, vendors } = filterData;
    if (allProductCategory) {
      values.internal_product_groups = [-1]
    }
    let data = {
      id,
      name,
      limit,
      vendors: vendors || [],
      internal_product_groups: values.internal_product_groups || []
    };
    editMutate(data);
  };
  const allGroupCheck = (e) => {
    setAllProductCategory(e.target.checked)
  };

  return (
    <Modal
      forceRender
      title={<Title level={5}>{filterData && filterData.id ? "Edit" : "New"} White list internal product groups</Title>}
      className="app-modal"
      centered
      visible={isInternalModal}
      onCancel={() => {
        resetState();
      }}
      footer={false}
      destroyOnClose={true}
      width={460}
    >
      <Form
        requiredMark={"optional"}
        name="basic"
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          remember: true,

        }}>
        <Row gutter={[48, 20]}>
          <Col span={24}>
            <Form.Item
              label="White list Product Category"
              name="internal_product_groups"
              rules={[
                {
                  required: !allProductCategory,
                  message: "Please select at least one Product Category",
                },
              ]}
            >
              {productCategoryLoading ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%', // or a specific height like '200px'
                  }}
                >
                  <Spin spinning={true} />
                </div>
              ) : (
                <Select
                  disabled={allProductCategory}
                  mode="multiple"
                  showSearch
                  filterOption={true}
                  optionFilterProp="children"
                  style={{ width: "100%" }}
                  placeholder="select product category"
                  allowClear
                >
                  {productCategory?.success &&
                    productCategory?.data?.records.map((el) => (
                      <Option key={el.id} id={el.id} value={el.id}>
                        {el.internal_name}
                      </Option>
                    ))}
                </Select>
              )}

            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label="White list All Product Category"
              name="allProductGroup"
            >
              <Checkbox checked={allProductCategory} onChange={allGroupCheck}>All Category Group</Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Divider />
            <Form.Item>
              <Button
                loading={editLoading || productCategoryLoading}
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
              >
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};
