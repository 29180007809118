import "./App.css";
import {
  LoginPage,
  OrdersPage,
  DelayedOrdersPage,
  ResturantsPage,
  ProductsReviewPage,
  RegionsPage,
  ZonesPage,
  ProductGroupsPage,
  ProductItemsPage,
  ChainsPage,
  VendorsPage,
  EmployeesPage,
  MediaPage,
  ClientsPage,
  GovernoratesPage,
  ResturantCategoriesPage,
  StatusCodesPage,
  VmsSettingPage,
  ReferalCodesPage,
  ReferralSettingsPage,
  PwaSettingsPage,
  VoucherSettingsPage,
  ContarctsPage,
  ResturantsReviewPage,
  OrderStatusPage,
  PaymentTypePage,
  DeclineReasonsPage,
  ReviewReasonsPage,
  DiscountsPage,
  ProductSubItemsPage,
  SubProductGroupPage,
  OrderViewPage,
  MsgTemplatePage,
  TokensPage,
  DiscountCapPage,
  VendorLogPage,
  BlockReasonPage,
  VendorPaymentPage,
  BulkVouchersPage,
  ServiceFeePage,
  FreeDeliveryPage,
  CompoundVoucherPage,
  BikerDispatchPage,
} from "./pages";
import { AuthGroupPage } from "./pages/authGroup";
import { useApp } from "./store";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Layout, Spin } from "antd";
import { AppHeader } from "./comonents";
import { DeliveryFeePage } from "./pages/delivery_fee";
import { BikerReviewPage } from "./pages/biker_review";
import { VouchersPage } from "./pages/vendor_discounts";
import { DeliveryDistancePage } from "./pages/delivery_distance";
import { AppBannersPage } from "./pages/appBanners";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./queryClient";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";
import { CustomErrorBoundary } from "./comonents/CustomErrorBoundry/index";
import authFunc from "./helper/auth";
import { ApologySettingsPage } from "./pages/apologySettings";
import { ResetPassword } from "./comonents/global/resetPassword";
import { WaterfallProcess } from "./pages/waterfall_process";
import { WaterfallProcessAction } from "./pages/waterfall_process_action";
import { VendorPerformancePage } from "./pages/vendor_weights_settings";
import { DistanceSettingsPage } from "./pages/distance_settings";
import { SortingSettingsPage } from "./pages/sorting_settings";
import { WeightsLogPage } from "./pages/weights_logs";
import { VendorPerformanceScorePage } from "./pages/vendor_performance_score";
import { VendorPerformanceCycleSettings } from "./pages/vendor_performance_cycle_settings/VendorPerformanceCycleSettings";
import { CompensationSettingsPage } from "./pages/compenstation_settings";
import { ProductWeightsPage } from "./pages/product_weights_settings";
import { InternalProductCategoryPage } from "./pages/internal_product_category";
import { InsideVendorPage } from "./pages/inside_vendor_page_settings";
import { MealSuggestionsPage } from "./pages/meal_suggestions_page_settings";

const { Content } = Layout;

function App() {
  const { isLogin, setIsLogin, setUser, appLoading, setAppLoading } = useApp();
  let location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("bo_token");
    const _user = localStorage.getItem("bo_user");
    if (token && _user) {
      setIsLogin(true);
      setUser(JSON.parse(_user));
    }
  }, []);

  useEffect(() => {
    setAppLoading(false);
  }, []);

  return (
    <CustomErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <Layout className="layout">
          {isLogin ? (
            location?.pathname.split("/")[1] !== "order" ? (
              <AppHeader />
            ) : null
          ) : null}
          <Content className="app-content">
            <div className="app-container" style={{ marginTop: 40 }}>
              <Spin spinning={appLoading}>
                {!appLoading && (
                  <Routes>
                    <Route exact path="/login" element={<LoginPage />} />
                    <Route
                      exact
                      path="/"
                      element={
                        <PrivateRoute Component={OrdersPage} auth={isLogin} />
                      }
                    />
                    <Route
                      exact
                      path="/delayed_orders"
                      element={
                        <PrivateRoute
                          Component={DelayedOrdersPage}
                          auth={isLogin && authFunc("ORDER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/order/:id"
                      element={
                        <PrivateRoute
                          Component={OrderViewPage}
                          auth={isLogin && authFunc("ORDER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/order_status"
                      element={
                        <PrivateRoute
                          Component={OrderStatusPage}
                          auth={isLogin && authFunc("ORDER_STATUS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/payment_typ"
                      element={
                        <PrivateRoute
                          Component={PaymentTypePage}
                          auth={isLogin && authFunc("PAYMENT_TYPE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/review_reasons"
                      element={
                        <PrivateRoute
                          Component={ReviewReasonsPage}
                          auth={isLogin && authFunc("REVIEW_REASON_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/decline_reason"
                      element={
                        <PrivateRoute
                          Component={DeclineReasonsPage}
                          auth={isLogin && authFunc("REASON_READ")}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/resturants"
                      element={
                        <PrivateRoute
                          Component={ResturantsPage}
                          auth={isLogin && authFunc("VENDOR_READ")}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/weights_settings"
                      element={
                        <PrivateRoute
                          Component={VendorPerformancePage}
                          auth={isLogin && authFunc("WEIGHTS_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/biker_dispatch"
                      element={
                        <PrivateRoute
                          Component={BikerDispatchPage}
                          auth={isLogin && authFunc("BIKER_DISPATCH_UPDATE")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/chains"
                      element={
                        <PrivateRoute
                          Component={ChainsPage}
                          auth={isLogin && authFunc("VENDOR_CHAIN_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/clients"
                      element={
                        <PrivateRoute
                          Component={ClientsPage}
                          auth={isLogin && authFunc("CLIENT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/tokens"
                      element={
                        <PrivateRoute
                          Component={TokensPage}
                          auth={isLogin && authFunc("TOKEN_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_performance_score"
                      element={
                        <PrivateRoute
                          Component={VendorPerformanceScorePage}
                          auth={isLogin && authFunc("SCORE_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_performance_cycle_settings"
                      element={
                        <PrivateRoute
                          Component={VendorPerformanceCycleSettings}
                          auth={isLogin && authFunc("CYCLE_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/compensation_settings"
                      element={
                        <PrivateRoute
                          Component={CompensationSettingsPage}
                          auth={
                            isLogin && authFunc("COMPENSATION_SETTINGS_READ")
                          }
                        />
                      }
                    />
                    <Route
                      exact
                      path="/msg_template"
                      element={
                        <PrivateRoute
                          Component={MsgTemplatePage}
                          auth={isLogin && authFunc("MSG_TEMPLATE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/product_weights"
                      element={
                        <PrivateRoute
                          Component={ProductWeightsPage}
                          auth={
                            isLogin
                            && authFunc("PRODUCT_WEIGHTS_CREATE")
                          }
                        />
                      }
                    />


                    <Route
                      exact
                      path="/productTrendingCarousels"
                      element={
                        <PrivateRoute
                          Component={InsideVendorPage}
                          auth={
                            isLogin
                            && authFunc("PRODUCT_CAROUSEL_READ")
                          }
                        />
                      }
                    />

                    <Route
                      exact
                      path="/meal_suggestions"
                      element={
                        <PrivateRoute
                          Component={MealSuggestionsPage}
                          auth={
                            isLogin
                            && authFunc("MEAL_SUGGESTIONS_READ")
                          }
                        />
                      }
                    />
                    <Route
                      exact
                      path="/authGroup"
                      element={
                        <PrivateRoute
                          Component={AuthGroupPage}
                          auth={isLogin && authFunc("AUTH_GROUP_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendors"
                      element={
                        <PrivateRoute
                          Component={VendorsPage}
                          auth={isLogin && authFunc("VENDOR_USER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/employees"
                      element={
                        <PrivateRoute
                          Component={EmployeesPage}
                          auth={isLogin && authFunc("USER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vouchers"
                      element={
                        <PrivateRoute
                          Component={VouchersPage}
                          auth={isLogin && authFunc("VOUCHER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/discountCap"
                      element={
                        <PrivateRoute
                          Component={DiscountCapPage}
                          auth={isLogin && authFunc("DISCOUNT_CAP_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/deliveryDiscount"
                      element={
                        <PrivateRoute
                          Component={FreeDeliveryPage}
                          auth={isLogin && authFunc("DELIVERY_DISCOUNT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/discounts"
                      element={
                        <PrivateRoute
                          Component={DiscountsPage}
                          auth={isLogin && authFunc("VENDOR_DISCOUNT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/compound_voucher"
                      element={
                        <PrivateRoute
                          Component={CompoundVoucherPage}
                          auth={isLogin && authFunc("VOUCHER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/delivery_fee"
                      element={
                        <PrivateRoute
                          Component={DeliveryFeePage}
                          auth={isLogin && authFunc("FEE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/service_fee"
                      element={
                        <PrivateRoute
                          Component={ServiceFeePage}
                          auth={isLogin && authFunc("FEE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/delivery_time"
                      element={
                        <PrivateRoute
                          Component={DeliveryDistancePage}
                          auth={isLogin && authFunc("DISTANCE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/products_review"
                      element={
                        <PrivateRoute
                          Component={ProductsReviewPage}
                          auth={isLogin && authFunc("PRODUCT_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/biker_review"
                      element={
                        <PrivateRoute
                          Component={BikerReviewPage}
                          auth={isLogin && authFunc("BIKER_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturants_review"
                      element={
                        <PrivateRoute
                          Component={ResturantsReviewPage}
                          auth={isLogin && authFunc("VENDOR_REVIEW_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/governorates"
                      element={
                        <PrivateRoute
                          Component={GovernoratesPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/regions"
                      element={
                        <PrivateRoute
                          Component={RegionsPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/zones"
                      element={
                        <PrivateRoute
                          Component={ZonesPage}
                          auth={isLogin && authFunc("AREA_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/product_group"
                      element={
                        <PrivateRoute
                          Component={ProductGroupsPage}
                          auth={isLogin && authFunc("PRODUCT_GROUP_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/status_codes"
                      element={
                        <PrivateRoute
                          Component={StatusCodesPage}
                          auth={isLogin && authFunc("STATUS_CODE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/block_reason"
                      element={
                        <PrivateRoute
                          Component={BlockReasonPage}
                          auth={isLogin && authFunc("BLOCK_REASON_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/VMS_settings"
                      element={
                        <PrivateRoute
                          Component={VmsSettingPage}
                          auth={isLogin && authFunc("VMS_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/referal_codes"
                      element={
                        <PrivateRoute
                          Component={ReferalCodesPage}
                          auth={isLogin && authFunc("REFERRAL_CODE_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_payment"
                      element={
                        <PrivateRoute
                          Component={VendorPaymentPage}
                          auth={
                            isLogin
                            // && authFunc("VENDOR_PAYMENT_READ")
                          }
                        />
                      }
                    />
                    <Route
                      exact
                      path="/vendor_log"
                      element={
                        <PrivateRoute
                          Component={VendorLogPage}
                          auth={isLogin && authFunc("VENDOR_LOG_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/weights_logs"
                      element={
                        <PrivateRoute
                          Component={WeightsLogPage}
                          auth={
                            isLogin
                            // && authFunc("WEIGHT_LOG_READ")
                          }
                        />
                      }
                    />
                    <Route
                      exact
                      path="/referral_settings"
                      element={
                        <PrivateRoute
                          Component={ReferralSettingsPage}
                          auth={isLogin && authFunc("REFERRAL_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/distance_settings"
                      element={
                        <PrivateRoute
                          Component={DistanceSettingsPage}
                          auth={isLogin && authFunc("DISTANCE_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/sorting_settings"
                      element={
                        <PrivateRoute
                          Component={SortingSettingsPage}
                          auth={isLogin && authFunc("SORTING_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/apology_settings"
                      element={
                        <PrivateRoute
                          Component={ApologySettingsPage}
                          auth={isLogin && authFunc("APOLOGY_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/PWA_settings"
                      element={
                        <PrivateRoute
                          Component={PwaSettingsPage}
                          auth={isLogin && authFunc("SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/voucher_settings"
                      element={
                        <PrivateRoute
                          Component={VoucherSettingsPage}
                          auth={isLogin && authFunc("VOUCHER_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/bulk_voucher"
                      element={
                        <PrivateRoute
                          Component={BulkVouchersPage}
                          auth={isLogin}
                        //auth={isLogin && authFunc("VOUCHER_SETTING_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturants/:id/products"
                      element={
                        <PrivateRoute
                          Component={ProductItemsPage}
                          auth={isLogin && authFunc("PRODUCT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturants/:resturantID/products/:productID/SubProductGroup"
                      element={
                        <PrivateRoute
                          Component={SubProductGroupPage}
                          auth={isLogin && authFunc("TOPPINGS_GROUP_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturants/:resturantID/products/:productID/SubProductGroup/:SubProductGroupID/topping"
                      element={
                        <PrivateRoute
                          Component={ProductSubItemsPage}
                          auth={isLogin && authFunc("TOPPINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturants/:id/contracts"
                      element={
                        <PrivateRoute
                          Component={ContarctsPage}
                          auth={isLogin && authFunc("VENDOR_CONTRACT_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/media"
                      element={
                        <PrivateRoute Component={MediaPage} auth={isLogin} />
                      }
                    />
                    <Route
                      exact
                      path="/app_banners"
                      element={
                        <PrivateRoute
                          Component={AppBannersPage}
                          auth={isLogin && authFunc("APPBANNER_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/resturant_categories"
                      element={
                        <PrivateRoute
                          Component={ResturantCategoriesPage}
                          auth={isLogin && authFunc("CATEGORY_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/waterfall_process"
                      element={
                        <PrivateRoute
                          Component={WaterfallProcess}
                          auth={isLogin && authFunc("WATERFALL_SETTINGS_READ")}
                        />
                      }
                    />
                    <Route
                      exact
                      path="/waterfall_process_action/:id"
                      element={
                        <PrivateRoute
                          Component={WaterfallProcessAction}
                          auth={isLogin && authFunc("WATERFALL_SETTINGS_READ")}
                        />
                      }
                    />

                    <Route
                      exact
                      path="/Internal-Product-Category"
                      element={
                        <PrivateRoute
                          Component={InternalProductCategoryPage}
                          auth={isLogin && authFunc("INTERNAL_PRODUCT_GROUP_READ")}
                        />
                      }
                    />
                  </Routes>
                )}
              </Spin>
            </div>
          </Content>
        </Layout>
        <ResetPassword />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </CustomErrorBoundary>
  );
}

const PrivateRoute = ({ Component, auth }) => {
  let location = useLocation();
  return auth ? (
    <Component />
  ) : (
    <Navigate to={`/login?p=${location.pathname}`} />
  );
};

export default App;
