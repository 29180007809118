import create from "zustand";

export const useMealSuggestions = create((set) => ({
  isCreateModal: false,
  isVendorModal: false,
  isInternalModal: false,
  filterData: null,
  queryKey: "",
  setQueryKey: (queryKey) => set({ queryKey }),
  setIsVendorModal: (isVendorModal) => set({ isVendorModal }),
  setIsInternalModal: (isInternalModal) => set({ isInternalModal }),
  setIsCreateModal: (isCreateModal) => set({ isCreateModal }),
  setFilterData: (filterData) => set({ filterData }),



}));
