import { Card, Input, Button, Breadcrumb } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { PureTable } from "./table";
import { PureCreate } from "./create";
import { PureVendorModal } from "./vendorsModal";
import { PureInternalModal } from "./InternalProductModal";
import { useMealSuggestions } from "./store";
import authFunc from "../../helper/auth";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

const { Search } = Input;

export const MealSuggestionsPage = () => {
  const { setIsCreateModal, queryKey, setQueryKey } = useMealSuggestions();
  const [search, setSearch] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (queryKey) {
      setSearch(queryKey);
    }
  }, []);

  useEffect(() => {
    if (searchParams.get("q")) {
      setSearch(searchParams.get("q"));
      setQueryKey(searchParams.get("q"));
    } else {
      setSearch("");
      setQueryKey("");
    }
  }, [searchParams.get("q")]);





  return (
    <div className="app-page">
      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Settings</Breadcrumb.Item>
        <Breadcrumb.Item>vendors Meal Suggestions</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <div className="app-space-between">
          <Search
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            onSearch={(val) => {
              searchParams.set("q", val);
              searchParams.set("page_num", 1);
              setSearchParams(searchParams);
              setQueryKey(val);
            }}


            style={{ width: 340 }}
            placeholder="Write To Search..."
          />
          <Button
            disabled={!authFunc("MEAL_SUGGESTIONS_CREATE")}
            onClick={() => setIsCreateModal(true)}
            type="primary"
            icon={<PlusOutlined />}
          >
            Create New
          </Button>
        </div>
        <br />
        <PureTable />
        <PureCreate />
        <PureVendorModal />
        <PureInternalModal />
      </Card>
    </div>
  );
};
